// NOTE: THIS IS ONLY MEANT TO SHOWCASE THE ASSET COCKPIT IN THE FIRST RELEASE AND WILL BE REMOVED WHEN THE BACKEND IS SET UP

import { AnalysisResult, SummaryKPIDevelopmentResult } from '@aedifion.io/aedifion-api'

function sampleDataForEnergyCostsEnglish (): AnalysisResult {
  return {
    alphanumeric_id: 'energy_cost_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Energy Cost',
    function_name: 'energy_cost_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Yellow,
    summary_kpi: {
      current: {
        value: 1.39,
        unit: '€/m^2/M',
        time_reference: 'in February',
      },
      development: {
        value: -2.1,
        unit: '%',
        time_reference: 'Compared to last month',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
      },
    },
    interpretation: 'Energy costs can be reduced',
    recommendation: [
      'The energy cost is the sum of the costs of the different energy sources of your building.',
      'The energy costs are higher than the average of the same type of building.',
      'You can reduce costs by optimizing the operation and upgrading your control system.',
      'aedifion can help you with reducing energy costs. Ask aedifion for more information.',
    ],
    plots: [
      {
        title: 'Building Benchmark',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio average',
            values: [
              1.1,
            ],
          },
          {
            name: 'This building',
            values: [
              1.39,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 0,
            },
            {
              name: 'yellow',
              value: 1.2,
            },
            {
              name: 'red',
              value: 3,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '€/m^2/M',
      },
      {
        title: 'Compared to last months',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Energy Cost',
            values: [
              1.15,
              1.42,
              1.39,
            ],
          },
        ],
        categories: [
          'December',
          'January',
          'February',
        ],
        unit: '€/m^2/M',
      },
      {
        title: 'By energy source',
        identifier: 'energy_consumption',
        type: 'pie_chart',
        data: [
          {
            name: 'Gas',
            values: [
              0.39,
            ],
          },
          {
            name: 'Electricity',
            values: [
              1.0,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForEnergyCostsGerman (): AnalysisResult {
  return {
    alphanumeric_id: 'energy_cost_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Energiekosten',
    function_name: 'energy_cost_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Yellow,
    summary_kpi: {
      current: {
        value: 1.39,
        unit: '€/m^2/M',
        time_reference: 'im Februar',
      },
      development: {
        value: -2.1,
        unit: '%',
        time_reference: 'im Monatsvergleich',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
      },
    },
    interpretation: 'Die Energiekosten sollten gesenkt werden',
    recommendation: [
      'Die Energiekosten sind die Summe aus den Kosten für die unterschiedlichen Energiequellen deines Gebäudes.',
      'Die Energiekosten sind höher der Durchschnitt desselben Gebäudetyps.',
      'Durch Optimierung des Betriebs und Upgrade deiner Gebäudeautomation kannst du Kosten reduzieren.',
      'Frage aedifion, wenn du Unterstützung bei der Reduzierung der Energiekosten haben möchtest.',
    ],
    plots: [
      {
        title: 'Im Gebäudevergleich',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio Durchschnitt',
            values: [
              1.1,
            ],
          },
          {
            name: 'Dieses Gebäude',
            values: [
              1.39,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 0,
            },
            {
              name: 'yellow',
              value: 1.2,
            },
            {
              name: 'red',
              value: 3,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '€/m^2/M',
      },
      {
        title: 'Im Monatsvergleich',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Energiekosten',
            values: [
              1.15,
              1.42,
              1.39,
            ],
          },
        ],
        categories: [
          'Dezember',
          'Januar',
          'Februar',
        ],
        unit: '€/m^2/M',
      },
      {
        title: 'Nach Energiequelle',
        identifier: 'energy_consumption',
        type: 'pie_chart',
        data: [
          {
            name: 'Gas',
            values: [
              0.39,
            ],
          },
          {
            name: 'Strom',
            values: [
              1.0,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Informationen zur Durchführung der Analyse und Probleme die dabei aufgetreten sind.',
    ],
  }
}

function sampleDataForCo2EmissionsEnglish (): AnalysisResult {
  return {
    alphanumeric_id: 'co2_emission_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    function_name: 'co2_emission_analysis',
    display_name: 'CO2 Emissions',
    signal_color: AnalysisResult.SignalColorEnum.Red,
    summary_kpi: {
      current: {
        value: 9.2,
        unit: 'kg/m^2/M',
        time_reference: 'in February',
      },
      development: {
        value: -1.5,
        unit: '%',
        time_reference: 'Compared to last month',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
      },
    },
    interpretation: 'CO2 emissions should be reduced',
    recommendation: [
      'The CO2 emissions are the sum of the CO2 emissions for the different energy sources of your building.',
      'The CO2 emissions are higher than the average for the same building type.',
      'You can reduce emissions by optimizing operation and upgrading your control system.',
      'aedifion can help you reducing CO2 emissions. Ask aedifion for more information.',
    ],
    plots: [
      {
        title: 'Building Benchmark',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio average',
            values: [
              6.1,
            ],
          },
          {
            name: 'This building',
            values: [
              9.2,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 0,
            },
            {
              name: 'yellow',
              value: 7,
            },
            {
              name: 'red',
              value: 11,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: 'kg/m^2/M',
      },
      {
        title: 'Compared to last months',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'CO2-Emissionen',
            values: [
              8.6,
              9.4,
              9.2,
            ],
          },
        ],
        categories: [
          'December',
          'January',
          'February',
        ],
        unit: 'kg/m^2/M',
      },
      {
        title: 'By energy source',
        identifier: 'co2_emissionen',
        type: 'pie_chart',
        data: [
          {
            name: 'Gas',
            values: [
              3,
            ],
          },
          {
            name: 'Electricity',
            values: [
              6.2,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForCo2EmissionsGerman (): AnalysisResult {
  return {
    alphanumeric_id: 'co2_emission_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    function_name: 'co2_emission_analysis',
    display_name: 'CO2-Emissionen',
    signal_color: AnalysisResult.SignalColorEnum.Red,
    summary_kpi: {
      current: {
        value: 9.2,
        unit: 'kg/m^2/M',
        time_reference: 'im Februar',
      },
      development: {
        value: -1.5,
        unit: '%',
        time_reference: 'im Monatsvergleich',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
      },
    },
    interpretation: 'Die CO2-Emissionen sollten gesenkt werden',
    recommendation: [
      'Die CO2-Emissionen sind die Summe aus den CO2-Emissionen für die unterschiedlichen Energiequellen deines Gebäudes.',
      'Die CO2-Emissionen sind höher als der Durchschnitt desselben Gebäudetyps.',
      'Durch Optimierung des Betriebs und Upgrade deiner Gebäudeautomation kannst du die Emissionen reduzieren.',
      'Frage aedifion, wenn du Unterstützung bei der Reduzierung der CO2-Emissionen haben möchtest.',
    ],
    plots: [
      {
        title: 'Im Gebäudevergleich',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio Durchschnitt',
            values: [
              6.1,
            ],
          },
          {
            name: 'Dieses Gebäude',
            values: [
              9.2,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 0,
            },
            {
              name: 'yellow',
              value: 7,
            },
            {
              name: 'red',
              value: 11,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: 'kg/m^2/M',
      },
      {
        title: 'Im Monatsvergleich',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'CO2-Emissionen',
            values: [
              8.6,
              9.4,
              9.2,
            ],
          },
        ],
        categories: [
          'Dezember',
          'Januar',
          'Februar',
        ],
        unit: 'kg/m^2/M',
      },
      {
        title: 'Nach Energiequelle',
        identifier: 'co2_emissionen',
        type: 'pie_chart',
        data: [
          {
            name: 'Gas',
            values: [
              3,
            ],
          },
          {
            name: 'Strom',
            values: [
              6.2,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Informationen zur Durchführung der Analyse und Probleme die dabei aufgetreten sind.',
    ],
  }
}

function sampleDataForHvacAvailabilityEnglish (): AnalysisResult {
  return {
    alphanumeric_id: 'technical_availability_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Availability of TBE',
    function_name: 'technical_availability_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 97,
        unit: '% TBE availability',
        time_reference: 'in February',
      },
      development: {
        value: 4.1,
        unit: '%',
        time_reference: 'Compared to last month',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'The availability of the TBE is fine',
    recommendation: [
      'Availability of the technical building equipment (TBE) reflects the technical availability of your TBE.',
      'Technical availability is above the average for the same type of building.',
      'You can efficiently retain availability by avoiding operating modes that promote wear, implementing predictive maintenance, and upgrading your control system.',
      'aedifion can help you improve availability. Ask aedifion for more information.',
    ],
    plots: [
      {
        title: 'Building Benchmark',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio average',
            values: [
              89,
            ],
          },
          {
            name: 'This building',
            values: [
              97,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'red',
              value: 50,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'green',
              value: 90,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '%',
      },
      {
        title: 'Compared to last months',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Technical Availability',
            values: [
              81,
              93,
              97,
            ],
          },
        ],
        categories: [
          'December',
          'January',
          'February',
        ],
        unit: '%',
      },
      {
        title: 'Downtimes by equipment',
        identifier: 'technical_availability',
        type: 'pie_chart',
        data: [
          {
            name: 'Heating',
            values: [
              3,
            ],
          },
          {
            name: 'Elevators',
            values: [
              18,
            ],
          },
          {
            name: 'Ventilation',
            values: [
              0.5,
            ],
          },
          {
            name: 'Cooling',
            values: [
              0,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForHvacAvailabilityGerman (): AnalysisResult {
  return {
    alphanumeric_id: 'technical_availability_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Zuverlässigkeit TGA',
    function_name: 'technical_availability_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 97,
        unit: '% TGA Verfügbarkeit',
        time_reference: 'im Februar',
      },
      development: {
        value: 4.2,
        unit: '%',
        time_reference: 'im Monatsvergleich',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'Die Zuverlässigkeit der TGA ist gut',
    recommendation: [
      'Die Verfügbarkeit der Technischen Gebäudeausrüstung (TGA) spiegelt die technische Verfügbarkeit deiner TGA wider.',
      'Die technische Verfügbarkeit liegt über dem Durchschnitt desselben Gebäudetyps.',
      'Du kannst die Verfügbarkeit effizient aufrechterhalten, indem du verschleißfördernde Betriebsweisen vermeidest, vorausschauend wartest und ein Upgrade deiner Gebäudeautomation durchführst',
      'Frage aedifion, wenn du Unterstützung zur Steigerung der TGA Verfügbarkeit haben möchtest.',
    ],
    plots: [
      {
        title: 'Im Gebäudevergleich',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio Durchschnitt',
            values: [
              89,
            ],
          },
          {
            name: 'Dieses Gebäude',
            values: [
              97,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'red',
              value: 50,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'green',
              value: 90,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '%',
      },
      {
        title: 'Im Monatsvergleich',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Technische Verfügbarkeit',
            values: [
              81,
              93,
              97,
            ],
          },
        ],
        categories: [
          'Dezember',
          'Januar',
          'Februar',
        ],
        unit: '%',
      },
      {
        title: 'Ausfallzeit nach Gewerk',
        identifier: 'technical_availability',
        type: 'pie_chart',
        data: [
          {
            name: 'Heizung',
            values: [
              8,
            ],
          },
          {
            name: 'Aufzüge',
            values: [
              40,
            ],
          },
          {
            name: 'Belüftung',
            values: [
              0,
            ],
          },
          {
            name: 'Kühlung',
            values: [
              0.5,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Informationen zur Durchführung der Analyse und Probleme die dabei aufgetreten sind.',
    ],
  }
}

function sampleDataForWellbeingEnglish (): AnalysisResult {
  return {
    alphanumeric_id: 'wellbeing_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Wellbeing',
    function_name: 'wellbeing_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 93,
        unit: '% wellbeing',
        time_reference: 'in February',
      },
      development: {
        value: 2.1,
        unit: '%',
        time_reference: 'Compared to last month',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'Wellbeing inside the building is fine',
    recommendation: [
      'Wellbeing describes the satisfaction of users of a building and consists of the air quality and the thermal comfort.',
      'Satisfaction is in the average of this type of building.',
      'Air quality and thermal comfort can be improved futher by optimizing operations and upgrading the controls',
      'aedifion can help you providing more wellbeing. Ask aedifion for more information.',
    ],
    plots: [
      {
        title: 'Building Benchmark',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio average',
            values: [
              82,
            ],
          },
          {
            name: 'This building',
            values: [
              93,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 90,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'red',
              value: 50,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: 'wellbeing in %',
      },
      {
        title: 'Compared to last months',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Wellbeing',
            values: [
              82,
              91,
              93,
            ],
          },
        ],
        categories: [
          'December',
          'January',
          'Februar',
        ],
        unit: '%',
      },
      {
        title: 'Reducion by cause',
        identifier: 'wellbeing',
        type: 'pie_chart',
        data: [
          {
            name: 'Air quality',
            values: [
              13,
            ],
          },
          {
            name: 'Too warm',
            values: [
              65,
            ],
          },
          {
            name: 'Too cold',
            values: [
              22,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForWellbeingGerman (): AnalysisResult {
  return {
    alphanumeric_id: 'wellbeing_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Wellbeing',
    function_name: 'wellbeing_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 93,
        unit: '% Wohlbefinden',
        time_reference: 'im Februar',
      },
      development: {
        value: 2.1,
        unit: '%',
        time_reference: 'im Monatsvergleich',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'Das Wohlbefinden im Gebäude ist gut',
    recommendation: [
      'Wellbeing beschreibt die Zufriedenheit der Gebäudenutzerinnen und -nutzer deines Gebäudes und setzt sich aus der Luftqualität und dem thermischen Komfort zusammen.',
      'Die Zufriedenheit liegt im Durchschnitt desselben Gebäudetyps.',
      'Durch Optimierung des Betriebs und Upgrade deiner Regelung kannst du die Luftqualität und den thermischen Komfort weiter verbessern.',
      'Frage aedifion, wenn du Unterstützung zur Steigerung des Wohlbefinden haben möchtest.',
    ],
    plots: [
      {
        title: 'Im Gebäudevergleich',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio Durchschnitt',
            values: [
              82,
            ],
          },
          {
            name: 'Dieses Gebäude',
            values: [
              93,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 90,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'red',
              value: 0,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: 'Wohlbefinden in %',
      },
      {
        title: 'Im Monatesvergleich',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Wohlbefinden',
            values: [
              82,
              91,
              93,
            ],
          },
        ],
        categories: [
          'Dezember',
          'Januar',
          'Februar',
        ],
        unit: '%',
      },
      {
        title: 'Reduktion nach Ursache',
        identifier: 'wellbeing',
        type: 'pie_chart',
        data: [
          {
            name: 'Luftqualität',
            values: [
              13,
            ],
          },
          {
            name: 'Zu warm',
            values: [
              65,
            ],
          },
          {
            name: 'Zu kalt',
            values: [
              22,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForOccupancyRateEnglish (): AnalysisResult {
  return {
    alphanumeric_id: 'occupancy_rate_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Occupancy Rate',
    function_name: 'occupancy_rate_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 92,
        unit: '%',
        time_reference: 'in February',
      },
      development: {
        value: 10,
        unit: '%',
        time_reference: 'Compared to last month',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'Occupancy is fine',
    recommendation: [
      'The occupancy rate is an indicator for space demad of tenants. In addition, high ratios are environmentally sustainable.',
      'The occupancy rate is average of the same type of building.',
      'You can improve occupancy by offering tenents felxible space usage and optimizing indoor wellbeing.',
      'aedifion can help you raising occupancy through great indoor wellbeing. Ask aedifion for more information.',
    ],
    plots: [
      {
        title: 'Building Benchmark',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio average',
            values: [
              77,
            ],
          },
          {
            name: 'This building',
            values: [
              92,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 90,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'red',
              value: 50,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '%',
      },
      {
        title: 'Compared to last months',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Occupancy rate',
            values: [
              79,
              83,
              92,
            ],
          },
        ],
        categories: [
          'December',
          'January',
          'February',
        ],
        unit: '%',
      },
      {
        title: 'Occupancy',
        identifier: 'occupancy_rate_analysis',
        type: 'pie_chart',
        data: [
          {
            name: 'Free',
            values: [
              8,
            ],
          },
          {
            name: 'Occupied',
            values: [
              92,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Information on how the analysis was performed and on the problems that occurred.',
    ],
  }
}

function sampleDataForOccupancyRateGerman (): AnalysisResult {
  return {
    alphanumeric_id: 'occupancy_rate_analysis',
    end: new Date('2022-02-28T00:00:00.000Z'),
    start: new Date('2022-02-01T00:00:00.000Z'),
    instance_id: 0,
    project_id: 0,
    status: 'Success',
    display_name: 'Flächennutzung',
    function_name: 'occupancy_rate_analysis',
    signal_color: AnalysisResult.SignalColorEnum.Green,
    summary_kpi: {
      current: {
        value: 92,
        unit: '%',
        time_reference: 'im Februar',
      },
      development: {
        value: 10,
        unit: '%',
        time_reference: 'im Monatsvergleich',
        arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
      },
    },
    interpretation: 'Die Flächennutzung ist gut',
    recommendation: [
      'Die Flächennutzung ist ein Indikator für die Flächennachfrage von Mietern. Hohe Nutzung gilt als ökologisch nachhaltig.',
      'Die Flächennutung liegt im Durchschnitt desselben Gebäudetyps.',
      'Sie können die Auslastung verbessern, indem Sie den Mietern eine flexible Raumnutzung anbieten und das Wohlbefinden in den Räumen optimieren.',
      'Frage aedifion, wenn du Unterstützung bei der Steigerung der Flächennutzung haben möchtest.',
    ],
    plots: [
      {
        title: 'Im Gebäudevergleich',
        identifier: 'benchmark',
        type: 'bullet_graph',
        data: [
          {
            name: 'Portfolio Durchschnitt',
            values: [
              77,
            ],
          },
          {
            name: 'Dieses Gebäude',
            values: [
              92,
            ],
          },
        ],
        extras: {
          thresholds: [
            {
              name: 'green',
              value: 90,
            },
            {
              name: 'yellow',
              value: 80,
            },
            {
              name: 'red',
              value: 50,
            },
          ],
        },
        categories: ['set', 'is'],
        unit: '%',
      },
      {
        title: 'Im Monatsvergleich',
        identifier: 'month_comparison',
        type: 'bar_chart',
        data: [
          {
            name: 'Flächenbelegung',
            values: [
              79,
              83,
              92,
            ],
          },
        ],
        categories: [
          'Dezember',
          'Januar',
          'Februar',
        ],
        unit: '%',
      },
      {
        title: 'Flächenbelegung',
        identifier: 'occupancy_rate_analysis',
        type: 'pie_chart',
        data: [
          {
            name: 'Frei',
            values: [
              8,
            ],
          },
          {
            name: 'Belegt',
            values: [
              92,
            ],
          },
        ],
        unit: '',
      },
    ],
    info: [
      'Informationen zur Durchführung der Analyse und Probleme die dabei aufgetreten sind.',
    ],
  }
}

export function getInstanceResultSampleData (instanceId: number, language: string): AnalysisResult | null {
  switch (instanceId) {
    case 0:
      if (language === 'de') {
        return sampleDataForEnergyCostsGerman()
      } else {
        return sampleDataForEnergyCostsEnglish()
      }
    case 1:
      if (language === 'de') {
        return sampleDataForCo2EmissionsGerman()
      } else {
        return sampleDataForCo2EmissionsEnglish()
      }
    case 2:
      if (language === 'de') {
        return sampleDataForHvacAvailabilityGerman()
      } else {
        return sampleDataForHvacAvailabilityEnglish()
      }
    case 3:
      if (language === 'de') {
        return sampleDataForWellbeingGerman()
      } else {
        return sampleDataForWellbeingEnglish()
      }
    case 4:
      if (language === 'de') {
        return sampleDataForOccupancyRateGerman()
      } else {
        return sampleDataForOccupancyRateEnglish()
      }
    default:
      return null
  }
}
