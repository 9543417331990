// NOTE: THIS IS ONLY MEANT TO SHOWCASE THE ASSET COCKPIT IN THE FIRST RELEASE AND WILL BE REMOVED WHEN THE BACKEND IS SET UP

import { AnalyticsResultsForComponent, Component, ComponentInProjectWithContext, SummaryKPIDevelopmentResult } from '@aedifion.io/aedifion-api'

export function sampleDataForComponentResultsEnglish (): AnalyticsResultsForComponent {
  const result: AnalyticsResultsForComponent = {}
  result.result_ids = [
    {
      alphanumeric_id: 'energy_cost_analysis',
      display_name: 'Energy Cost',
      function_name: 'energy_cost_analysis',
      instance_id: 0,
      result_id: 'fake_result-1',
      signal_color: 'yellow',
      summary_kpi: {
        current: {
          value: 1.39,
          unit: '€/m^2/M',
          time_reference: 'in February',
        },
        development: {
          value: -2.1,
          unit: '%',
          time_reference: 'Compared to last month',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
        },
      },
      interpretation: 'Energy costs can be reduced',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'co2_emission_analysis',
      instance_id: 1,
      result_id: 'fake_result-2',
      display_name: 'CO2 Emissions',
      function_name: 'co2_emission_analysis',
      signal_color: 'red',
      summary_kpi: {
        current: {
          value: 9.2,
          unit: 'kg/m^2/M',
          time_reference: 'in February',
        },
        development: {
          value: -1.5,
          unit: '%',
          time_reference: 'Compared to last month',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
        },
      },
      interpretation: 'CO2 emissions should be reduced',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'technical_availability_analysis',
      instance_id: 2,
      result_id: 'fake_result-3',
      display_name: 'Availability of TBE',
      function_name: 'technical_availability_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 97,
          unit: '% TBE availability',
          time_reference: 'in February',
        },
        development: {
          value: 4.1,
          unit: '%',
          time_reference: 'Compared to last month',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'The availability of the TBE is fine',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'wellbeing_analysis',
      instance_id: 3,
      result_id: 'fake_result-4',
      display_name: 'Wellbeing',
      function_name: 'wellbeing_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 93,
          unit: '% wellbeing',
          time_reference: 'in February',
        },
        development: {
          value: 2.1,
          unit: '%',
          time_reference: 'Compared to last month',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'Wellbeing inside the building is fine',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'occupancy_rate_analysis',
      instance_id: 4,
      result_id: 'fake_result-5',
      display_name: 'Occupancy rate',
      function_name: 'occupancy_rate_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 92,
          unit: '%',
          time_reference: 'in February',
        },
        development: {
          value: 10,
          unit: '%',
          time_reference: 'Compared to last month',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'Occupancy is fine',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
  ]
  return result
}

export function sampleDataForComponentResultsGerman (): AnalyticsResultsForComponent {
  const result: AnalyticsResultsForComponent = {}
  result.result_ids = [
    {
      alphanumeric_id: 'energy_cost_analysis',
      display_name: 'Energiekosten',
      function_name: 'energy_cost_analysis',
      instance_id: 0,
      result_id: 'fake_result-1',
      signal_color: 'yellow',
      summary_kpi: {
        current: {
          value: 1.39,
          unit: '€/m^2/M',
          time_reference: 'im Februar',
        },
        development: {
          value: -2.1,
          unit: '%',
          time_reference: 'im Monatsvergleich',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
        },
      },
      interpretation: 'Die Energiekosten sollten gesenkt werden',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'co2_emission_analysis',
      instance_id: 1,
      result_id: 'fake_result-2',
      display_name: 'CO2-Emissionen',
      function_name: 'co2_emission_analysis',
      signal_color: 'red',
      summary_kpi: {
        current: {
          value: 9.2,
          unit: 'kg/m^2/M',
          time_reference: 'im Februar',
        },
        development: {
          value: -1.5,
          unit: '%',
          time_reference: 'im Monatsvergleich',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Down,
        },
      },
      interpretation: 'Die CO2-Emissionen sollten gesenkt werden',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'technical_availability_analysis',
      instance_id: 2,
      result_id: 'fake_result-3',
      display_name: 'Zuverlässigkeit TGA',
      function_name: 'technical_availability_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 97,
          unit: '% TGA Verfügbarkeit',
          time_reference: 'im Februar',
        },
        development: {
          value: 4.1,
          unit: '%',
          time_reference: 'im Monatsvergleich',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'Die Zuverlässigkeit der TGA ist in Ordnung',
      start: new Date('2022-01-21T08:32:47.670Z'),
      end: new Date('2022-01-21T08:32:47.670Z'),
    },
    {
      alphanumeric_id: 'wellbeing_analysis',
      instance_id: 3,
      result_id: 'fake_result-4',
      display_name: 'Wellbeing',
      function_name: 'wellbeing_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 93,
          unit: '% Wohlbefinden',
          time_reference: 'im Februar',
        },
        development: {
          value: 2.1,
          unit: '%',
          time_reference: 'im Monatsvergleich',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'Das Wohlbefinden im Gebäude ist gut',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
    {
      alphanumeric_id: 'occupancy_rate_analysis',
      instance_id: 4,
      result_id: 'fake_result-5',
      display_name: 'Flächennutzung',
      function_name: 'occupancy_rate_analysis',
      signal_color: 'green',
      summary_kpi: {
        current: {
          value: 92,
          unit: '%',
          time_reference: 'im Februar',
        },
        development: {
          value: 10,
          unit: '%',
          time_reference: 'im Monatsvergleich',
          arrow: SummaryKPIDevelopmentResult.ArrowEnum.Up,
        },
      },
      interpretation: 'Die Flächennutzung ist gut',
      start: new Date('2022-02-01T00:00:00.000Z'),
      end: new Date('2022-02-28T00:00:00.000Z'),
    },
  ]
  return result
}

export function sampleDataForComponentResults (language: string): AnalyticsResultsForComponent {
  if (language === 'de') {
    return sampleDataForComponentResultsGerman()
  } else {
    return sampleDataForComponentResultsEnglish()
  }
}

export function sampleDataForBuildingComponent (projectId: number): ComponentInProjectWithContext {
  return {
    abbreviation: 'string',
    attributes: [
      {
        alphanumeric_id: 'B_TAR_WS_FRESH_CONSUM',
        id: 23,
        key: 'B_TAR_WS_FRESH_CONSUM',
        value: '17.6',
      },
      {
        alphanumeric_id: 'B+NFA',
        id: 25,
        key: 'B+NFA',
        value: '24000.0',
      },
      {
        alphanumeric_id: 'B+TYP',
        id: 26,
        key: 'B+TYP',
        value: 'office',
      },
      {
        alphanumeric_id: 'B+CERTS',
        id: 27,
        key: 'B+CERTS',
        value: '"{\\"DGNB\\":{\\"grade\\":{\\"de\\":\\"Gold\\",\\"en\\":\\"Gold\\"}},\\"WiredScore\\":{\\"grade\\":{\\"de\\":\\"Platinum\\",\\"en\\":\\"Platinum\\"}}}"',
      },
      {
        alphanumeric_id: 'B+GOA',
        id: 28,
        key: 'B+GOA',
        value: '"{ \\"period\\": {\\"de\\":\\"Januar bis März\\",\\"en\\":\\"January to March\\"}, \\"goals\\":[{ \\"names\\":{\\"de\\":\\"Reduziere Energiekosten um 5%\\",\\"en\\":\\"Reduce energy costs by 5%\\"},\\"bar_chart\\":{\\"WARNING\\":\\"Use highcharts data structure for name and values to build the plot and change bar_chart to the corresponding chart-key\\",\\"min\\":0,\\"act\\":3.8, \\"max\\": 5}},{\\"names\\":{\\"de\\":\\"Reduziere CO2-Emissionen um 10%\\",\\"en\\":\\"Reduce CO2 emissions by 10%\\"},\\"bar_chart\\":{\\"WARNING\\":\\"Use highcharts data structure for name and values to build the plot and change bar_chart to the corresponding chart-key\\",\\"min\\":0,\\"act\\":3, \\"max\\": 10}},{\\"names\\":{\\"de\\":\\"Verbessere das Wohlbefinden um 5%\\",\\"en\\":\\"Improve wellbeing by 5%\\"},\\"bar_chart\\":{\\"WARNING\\":\\"Use highcharts data structure for name and values to build the plot and change bar_chart to the corresponding chart-key\\",\\"min\\":0,\\"act\\":2.5, \\"max\\": 5}}]}"',
      },
      {
        alphanumeric_id: 'B_EFZ',
        id: 30,
        key: 'B_EFZ',
        value: '"{\\"zone\\":\\"A\\"}"',
      },
      {
        alphanumeric_id: 'B_EVS',
        id: 31,
        key: 'B_EVS',
        value: '"[{\\"name\\":{\\"de\\":\\"GEWERKALARM\\",\\"en\\":\\"EQUIPMENT ALERT\\"},\\"category\\":\\"equipment\\",\\"icon\\":\\"fa:far fa-air-conditioner\\",\\"active\\":true},{\\"name\\":{\\"de\\":\\"EINBRUCHALARM\\",\\"en\\":\\"INTRUSION ALERT\\"},\\"category\\":\\"intrusion\\",\\"icon\\":\\"fa:far fa-mask\\",\\"active\\":true},{\\"name\\":{\\"de\\":\\"SERVERRAUM - TEMPERATUR\\",\\"en\\":\\"SERVER ROOM - TEMPERATURE\\"},\\"category\\":\\"serverroom\\",\\"icon\\":\\"fa:far fa-server\\",\\"active\\": true},{\\"name\\":{\\"de\\":\\"IT-NETZWERKAUSFALL\\",\\"en\\":\\"IT NETWORK OUTAGE\\"},\\"category\\":\\"network\\",\\"icon\\":\\"fa:far fa-wifi\\",\\"active\\": true},{\\"name\\":{\\"de\\":\\"STROMAUSFALL\\",\\"en\\":\\"POWER FAILURE\\"},\\"category\\":\\"electricity\\",\\"icon\\": \\"fa:far fa-battery-slash\\",\\"active\\": true},{\\"name\\":{\\"de\\":\\"FEUERALARM\\",\\"en\\":\\"FIRE ALARM\\"},\\"category\\": \\"fire\\",\\"icon\\": \\"fa:far fa-fire-alt\\",\\"active\\": true},{\\"name\\":{\\"de\\":\\"BEHINDERTENNOTRUF\\",\\"en\\":\\"DISABLED EMERGENCY\\"},\\"category\\":\\"availability\\",\\"icon\\":\\"fa:far fa-wheelchair\\",\\"active\\": true},{\\"name\\":{\\"de\\":\\"KONFERENZRAUM - LUFTQUALITÄT\\",\\"en\\":\\"CONFERENCE ROOM - AIR QUALITY\\"},\\"category\\": \\"conferenceroom\\",\\"icon\\": \\"fa:far fa-users\\",\\"active\\": true}]"',
      },
    ],
    component: {
      alphanumeric_id: 'B',
      id: 15,
      nameDE: 'Gebäude',
      nameEN: 'Building',
      private: true,
    } as Component,
    display_name: 'Building Component Test | Gebäude',
    id: 101,
    nameDE: 'string',
    nameEN: 'Building Component Test',
    pins: [],
    project_id: projectId,
  }
}
