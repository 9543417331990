export enum DataType {
  String,
  Selection,
  Number,
  Year,
}

export type SelectOption = {
  title: string|number;
  value: string|number;
}

export type DataRow = {
  error?: string;
  key: string;
  name: string;
  selectOptions?: SelectOption[];
  type: DataType;
  unit?: string;
  value?: string;
}
