import { init, setTag } from '@sentry/vue'
import { app } from '@/main'
import { version as appVersion } from '@/../package.json'
import type { Integration } from '@sentry/core'
import { rewriteFramesIntegration } from '@sentry/browser'

/**
 * Initialized sentry for logging and tracing errors
 * @param integrations A list of integrations that should be registered
 * @param includeVue Whether to include the Vue environment in the sentry logging
 * @param initializationFailedErrorMessage Provide a custom error message if sentry could not be initialized due to missing environment parameters.
 */
export function setupSentry (
  integrations: Integration[],
  initializationFailedErrorMessage = 'Sentry: No sentry setup for this deployment.',
): void {
  // @ts-ignore
  const sentryDsn: string|undefined = globalThis.secrets.SENTRY_DSN
  if (sentryDsn !== undefined && import.meta.env.MODE === 'production') {
    init({
      app,
      autoSessionTracking: true,
      dsn: sentryDsn,
      environment: import.meta.env.MODE,
      integrations: [
        ...integrations,
        rewriteFramesIntegration({
          iteratee: (frame) => frame,
          prefix: 'app://',
          root: '/home/aedifion/aedifion.io/nginx/roots',
        }),
      ],
      logErrors: true,
      release: 'frontend@' + appVersion,
      tracesSampleRate: 0.33,
    })
    if (globalThis.configuration.REALM !== undefined) setTag('realm', globalThis.configuration.REALM)
    setTag('theme', import.meta.env.VITE_APP_THEME ?? 'aedifion')
  } else if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(
      `%c${initializationFailedErrorMessage}`,
      'font-size: 14px; background-color: white; color: black; padding: 5px; border-radius: 3px',
    )
  }
}
