export enum STATUS {
  ACK = 'ack',
  ASSIGN = 'assign',
  BLACKOUT = 'blackout',
  CLOSED = 'closed',
  EXPIRED = 'expired',
  OPEN = 'open',
  SHELVED = 'shelved',
  UNKNOWN = 'unknown',
}

export enum SEVERITY {
  CLEARED = 'cleared',
  CRITICAL = 'critical',
  DEBUG = 'debug',
  INDETERMINATE = 'indeterminate',
  INFORMATIONAL = 'informational',
  MAJOR = 'major',
  MINOR = 'minor',
  NORMAL = 'normal',
  OK = 'ok',
  SECURITY = 'security',
  TRACE = 'trace',
  UNKNOWN = 'unknown',
  WARNING = 'warning',
}

export type AlertAttributes = {
  [key: string]: string|number|null;
}

export type HistoryEntry = {
  event: string;
  href: string;
  id: string;
  severity: SEVERITY;
  status: STATUS;
  text: string;
  type: string;
  updateTime: string;
  user: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export type Alert = {
  attributes: AlertAttributes;
  correlate: string[];
  createTime: string;
  customer: string;
  duplicateCount: number;
  environment: string;
  event: string;
  group: string;
  history: HistoryEntry[];
  href: string;
  id: string;
  lastReceiveId: string;
  lastReceiveTime: string;
  origin: string;
  previousSeverity: string;
  rawData: unknown;
  receiveTime: string;
  repeat: boolean;
  resource: string;
  service: string[];
  severity: SEVERITY;
  status: STATUS;
  tags: string[];
  text: string;
  timeout: number;
  trendIndication: string;
  type: string;
  updateTime: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export type AlertsResponse = {
  alerts: Alert[];
  autoRefresh: boolean;
  lastTime: string;
  more: boolean;
  page: number;
  pageSize: number;
  pages: number;
  severityCounts: Partial<Record<SEVERITY, number>>;
  status: STATUS;
  statusCounts: Partial<Record<STATUS, number>>;
  total: number;
}

export type RequestParams = {
  [key: string]: string|string[]|number|undefined;
}

export type KeyValuePair = {
  key: string;
  value: string;
}
