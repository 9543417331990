import { PaginationMeta } from '@aedifion.io/aedifion-api'
import { reportError } from '@/utils/helpers/errors'

/**
 * Makes a paginated API request and returns the full response.
 * If the response has multiple pages, it makes additional requests for each page and combines the results.
 * @param request - A function that makes the API request with the given arguments.
 * @param params - A map or record of query parameters to be passed to the API request.
 * @returns The full API response, including all pages of results.
 */
export async function unPaginate<Response extends {
  items?: unknown[],
  meta?: PaginationMeta,
},
// eslint-disable-next-line @typescript-eslint/no-explicit-any
> (request: (...args: any[]) => Promise<Response>, params: Map<string, unknown>|Record<string, unknown>): Promise<Response> {
  let response: Response|null = null
  try {
    let initialReqParams
    if (params instanceof Map) {
      initialReqParams = [...params.values()]
    } else {
      initialReqParams = params
    }
    response = await request(initialReqParams)

    if (response.meta?.total_pages ?? 0 > 1) {
      const requests: Array<Promise<Response>> = []
      for (let page = 2; page <= response.meta!.total_pages; page++) {
        let newParams
        if (params instanceof Map) {
          newParams = new Map(params)
          newParams.set('page', page)
          requests.push(request(
            [...newParams.values()],
          ))
        } else {
          newParams = {
            ...params,
            page,
          }
          requests.push(request(newParams))
        }
      }

      const results: Response[] = await Promise.all(requests)

      for (const result of results) {
        response.items!.push(...result.items!)
      }
    }
  } catch (error) {
    reportError(error)
    throw error
  }
  return response
}
