import { StatusAndAlertsState } from './types'

export default {
  loadingSystemComponents: false,
  loadingSystemsOperationalStatusCodes: false,
  systemComponents: null,
  systemsOperationalStatusCodes: [],
} as StatusAndAlertsState

export function resetStoreState (state: StatusAndAlertsState): void {
  state.loadingSystemComponents = false
  state.loadingSystemsOperationalStatusCodes = false
  state.systemComponents = null
  state.systemsOperationalStatusCodes = []
}
