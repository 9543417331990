import { DateRangeObject, TagItem } from '@/vuex/data_points_view/types'
import { LocationQuery, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { areUnsortedEqual } from '@/utils/helpers/arrays'
import { createNextLocation } from './helpers'
import { DataPointWithContext } from '@aedifion.io/aedifion-api'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from '@/vuex/data_points_view/state'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

/**
 * Helper function that adds a new filter, defined by 'key' and 'values', to an existing list 'filters'.
 * If 'values' is a single string it can be appended as a new filter directly.
 * However, if 'values' is a list, one 'TagItem' has to be created per string in 'values.
 * @param filters The filters the new filters will be appended to.
 * @param key The key of the tag filter in the URL query, which is 'tag.' + the key of the tag.
 * @param values The values of the tag filter in the URL query.
 */
function addNewTagFiltersToFilterList (filters: TagItem[], key: string, values: string[]|string): void {
  const tagValues = Array.isArray(values) ? values as string[] : [values as string]
  for (const singleValue of tagValues) {
    filters.push({
      key: key.substr(key.indexOf('.') + 1),
      value: singleValue,
    })
  }
}

/**
 * Clears all data points view values that are displayed in the route query from the store.
 * @param store vuex store.
 */
export function clearDataPointsViewStore (store: Store<RootState>): RouteLocation|void {
  resetStoreState(store.state.data_points_view)
}

/**
 * Checks the store and the query for data points view values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
export function updateDataPointsViewQuery (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  const newQuery: LocationQuery = {}
  if (store.getters['data_points_view/search']) {
    newQuery.search = store.getters['data_points_view/search']
  }
  const selectedDatapoints: string[] = store.getters['data_points_view/getSelectedDatapointsHashIds']
  if (selectedDatapoints.length > 0) {
    newQuery.datapoints = selectedDatapoints.join(',')
  }
  const tagFilters: TagItem[] = store.getters['data_points_view/getTagFilters']
  for (const tagFilter of tagFilters) {
    const filterKey = `tag.${tagFilter.key}`
    if (newQuery[filterKey]) {
      if (Array.isArray(newQuery[filterKey])) {
        (newQuery[filterKey] as string[]).push(tagFilter.value)
      } else {
        newQuery[filterKey] = [newQuery[filterKey] as string, tagFilter.value]
      }
    } else {
      newQuery[filterKey] = tagFilter.value
    }
  }
  if (store.getters['data_points_view/isFavoritesFilterSet']) {
    newQuery.favorites = 'true'
  }
  if (store.getters['data_points_view/isWritableFilterSet']) {
    newQuery.writable = 'true'
  }
  const dateRange: [string, string?] = store.getters['data_points_view/getDateRange']
  newQuery.start = dateRange[0]
  newQuery.end = dateRange.length === 2 ? dateRange[1]! : dateRange[0]
  if (store.getters['data_points_view/zoom']) {
    const zoom: DateRangeObject = store.getters['data_points_view/zoom']
    newQuery.zoomStart = zoom.start.toISOString()
    newQuery.zoomEnd = zoom.end.toISOString()
  }
  if (store.state.data_points_view.liveViewActive) {
    newQuery.live = 'true'
  }

  if (store.getters['data_points_view/isShowingSetpointEditor']) {
    newQuery.setpoint = store.state.data_points_view.setpointId
  }

  if (Object.keys(newQuery).length > 0) {
    return createNextLocation(to, newQuery)
  }
}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param store vuex store.
 * @param to Target route with values to be stored.
 */
export function updateDataPointsViewStore (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  if (to.query.search) {
    store.commit('data_points_view/SET_SEARCH', to.query.search)
  }
  store.commit('data_points_view/SET_LIVE_VIEW_STATUS', 'live' in to.query)
  if (to.query.datapoints) {
    const newDatapointHashIds: string[] = (to.query.datapoints as string).split(',')
    const selectedDatapointHashIds: string[] = store.getters['data_points_view/getSelectedDatapointsHashIds']
    if (!areUnsortedEqual(newDatapointHashIds, selectedDatapointHashIds)) {
      store.dispatch('datapoints/fetchDatapoints', 1)
        .then(() => {
          store.dispatch('data_points_view/selectDatapoints', (to.query.datapoints as string).split(','))
        })
        .catch((error) => { // TODO: isn’t this catch useless?
          reportError(error)
        })
    }
  }
  if (to.query.start && to.query.end) {
    store.commit('data_points_view/SET_DATE_RANGE', [to.query.start, to.query.end])
  }
  if (to.query.zoomStart && to.query.zoomEnd) {
    store.commit('data_points_view/SET_ZOOM', {
      end: new Date(to.query.zoomEnd as string),
      start: new Date(to.query.zoomStart as string),
    })
  }
  store.commit('data_points_view/SET_FAVORITES_FILTER', 'favorites' in to.query)
  store.commit('data_points_view/SET_WRITABLE_FILTER', 'writable' in to.query)
  const tagFilters: TagItem[] = []
  for (const [key, value] of Object.entries(to.query)) {
    if (key.startsWith('tag.')) {
      addNewTagFiltersToFilterList(tagFilters, key, value as string[]|string)
    }
  }
  store.commit('data_points_view/SET_TAG_FILTERS', tagFilters)

  if (to.query.setpoint && !store.getters['data_points_view/setpointDatapoint']) {
    store.dispatch('datapoints/fetchDatapoint', to.query.setpoint)
      .then((datapoint: DataPointWithContext) => {
        store.commit('data_points_view/SET_SETPOINT_DATAPOINT', datapoint)
        store.commit('data_points_view/SET_SETPOINTS_DATAPOINT_ID', datapoint.hash_id)
      })
  }
}
