const initialApiRequestsCompletionCallbacks: (() => void)[] = []
let areInitialApiRequestsCompleted = false

export enum Priority {
  High = 1,
  Low,
}

/**
 * Flags the initial API requests as completed and runs the functions that were
 * registered using the `onInitialApiRequestsCompleted()` lifecycle hook.
 */
export const initialApiRequestsCompleted = () => {
  areInitialApiRequestsCompleted = true

  for (const callback of initialApiRequestsCompletionCallbacks) {
    callback()
  }

  initialApiRequestsCompletionCallbacks.splice(0)
}

/**
 * Registers a function to call once the initial API requests have completed. It
 * will be called immediately if those requests have already completed.
 * @param fn the function that should be registered.
 */
export function onInitialApiRequestsCompleted (fn: () => void, priority: Priority = Priority.Low): void {
  if (areInitialApiRequestsCompleted) {
    fn()
  } else {
    switch (priority) {
      case Priority.High:
        initialApiRequestsCompletionCallbacks.unshift(fn)
        break
      case Priority.Low:
        initialApiRequestsCompletionCallbacks.push(fn)
        break
    }
  }
}

/**
 * @returns a Promise that resolves as soon as the initial API requests have
 * completed.
 */
export async function waitForInitialApiRequestsToComplete (): Promise<void> {
  return new Promise((resolve) => {
    onInitialApiRequestsCompleted(resolve)
  })
}
