import { NavigationGuardNext, RouteLocationNormalized, RouteMeta } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { waitForInitialApiRequestsToComplete } from '@/utils/helpers/hooks'

export interface RoleRouteMeta {
  realmAdmin?: boolean,
  companyAdmin?: boolean,
  projectAdmin?: boolean,
}

interface CustomRouteMeta extends RouteMeta {
  requiresRole?: RoleRouteMeta
}

type CustomToRoute = Omit<RouteLocationNormalized, 'meta'> & { meta?: CustomRouteMeta }

interface RoleNavigationGuard {
  (to: CustomToRoute, from: RouteLocationNormalized, next: NavigationGuardNext): void
}

export const roleGuardMiddleware: () => RoleNavigationGuard = () => async (
  to: CustomToRoute,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore()
  let passedRoleCheck = false

  if (
    !to.meta?.requiresRole?.realmAdmin &&
      !to.meta?.requiresRole?.companyAdmin &&
      !to.meta?.requiresRole?.projectAdmin
  ) {
    passedRoleCheck = true
    return next()
  }

  await waitForInitialApiRequestsToComplete()

  const companyId = to.params.company === undefined ? undefined : parseInt(to.params.company as string)
  const projectId = to.params.project === undefined ? undefined : parseInt(to.params.project as string)
  if (
    !passedRoleCheck && (
      (to.meta?.requiresRole?.realmAdmin && userStore.isRealmAdmin) ||
      (to.meta?.requiresRole?.companyAdmin && userStore.isCompanyAdmin(companyId)) ||
      (to.meta?.requiresRole?.projectAdmin && userStore.isProjectAdmin(companyId, projectId))
    )
  ) {
    passedRoleCheck = true
    return next()
  }

  if (!passedRoleCheck) {
    return next({ name: 'forbidden' })
  }
}
