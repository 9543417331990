import { LocationQuery, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { createNextLocation } from './helpers'
import { useOptimizationStore } from '@/stores/optimization'

/**
 * Clears all optimization values that are displayed in the route query from the store.
 * @param store vuex store.
 */
export function clearOptimizationStore (): RouteLocation|void {
  const optimizationStore = useOptimizationStore()
  optimizationStore.clear()
}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param store vuex store.
 * @param to Target route with values to be stored.
 */
export function updateOptimizationStore (_store: unknown, to: RouteLocationNormalized): RouteLocation|void {
  const optimizationStore = useOptimizationStore()
  if (to.query.search && optimizationStore.search !== to.query.search.toString()) {
    optimizationStore.search = to.query.search.toString()
  }

  if (to.query.instance && optimizationStore.selectedInstanceId !== parseInt(to.query.instance.toString())) {
    optimizationStore.selectedInstanceId = parseInt(to.query.instance.toString())
  }

  if (to.query.result && optimizationStore.selectedResultId !== to.query.result.toString()) {
    optimizationStore.selectedResultId = to.query.result.toString()
  }

  if (to.query.followAnalysisPeriod !== undefined) {
    optimizationStore.followAnalysisPeriod = true
  } else {
    optimizationStore.followAnalysisPeriod = false
  }
}

/**
 * Checks the store and the query for operation cockpit values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
export function updateOptimizationQuery (_store: unknown, to: RouteLocationNormalized): RouteLocation|void {
  const newQuery: LocationQuery = {}

  const optimizationStore = useOptimizationStore()

  const storeSearch = optimizationStore.search
  if (storeSearch && storeSearch !== to.query.search) {
    newQuery.search = storeSearch
  }

  const storeSelectedInstanceId = optimizationStore.selectedInstanceId
  if (storeSelectedInstanceId && storeSelectedInstanceId.toString() !== to.query.instance) {
    newQuery.instance = storeSelectedInstanceId.toString()
  }

  const storeSelectedResultId = optimizationStore.selectedResultId
  if (storeSelectedResultId && storeSelectedResultId !== to.query.result) {
    newQuery.result = storeSelectedResultId
  }

  if (optimizationStore.followAnalysisPeriod) {
    newQuery.followAnalysisPeriod = 'true'
  }

  if (Object.keys(newQuery).length > 0) {
    return createNextLocation(to, newQuery)
  }
}
