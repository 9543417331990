<script setup lang="ts">
import { useI18n } from 'vue-i18n'

interface Props {
  column?: boolean;
  sticky?: boolean;
  titleKey: string;
}

const { t } = useI18n()

withDefaults(defineProps<Props>(), {
  sticky: false,
})
</script>

<template>
  <div :class="['d-flex', 'flex-wrap', { 'flex-column': column }, 'mb-8', { sticky }]">
    <h3 class="text-h3 text-neutral-darken4">
      {{ t(titleKey) }}
    </h3>
    <slot />
  </div>
</template>

<style lang="sass" scoped>
.sticky
  position: sticky
  top: 40px
  right: 0
  left: 0
  z-index: 1
</style>
