import { defaultLinks, germanLinks } from '../../../assets-repo/evolution-frontend/docsLinks'
import { DocsValue } from '../../../assets-repo/evolution-frontend/types'
import i18n from '@/i18n'
import texts from '@theme/texts'

export function textForLocale (german: string, english: string): string {
  return i18n.global.locale.value.includes('de') ? german : english
}

/**
 * Returns the original docs links object with its properties deeply replaced
 * with the new docs links object.
 */
const mergeDocsLinks = (originalDocsObject: DocsValue, newDocsObject: DocsValue): DocsValue => {
  const result = { ...originalDocsObject }
  for (const [key, value] of Object.entries(newDocsObject)) {
    if (typeof value === 'string') {
      result[key] = value
    } else {
      mergeDocsLinks(result[key] as DocsValue, newDocsObject[key] as DocsValue)
    }
  }
  return result
}

/**
 * Recursively transforms a DocsValue object into a flat object.
 * @param docsObject the object that should be flattened.
 * @param prefix a string that should be prefixed to all keys, and joined using
 * a dot. Example: `flattenDocsValue({ example: '' }, 'foo')` gives
 * `{ 'foo.example': '' }`.
 * @returns a flattened version of the object passed as first parameter.
 */
const flattenDocsValue = (docsObject: DocsValue, prefix = ''): { [T in string]: string } => {
  const result: { [T in string]: string } = {}
  for (const [key, value] of Object.entries(docsObject)) {
    if (typeof value === 'string') {
      result[prefix ? `${prefix}.${key}` : key] = value
    } else {
      const flatObject = flattenDocsValue(value, key)
      for (const [subKey, subValue] of Object.entries(flatObject)) {
        result[prefix ? `${prefix}.${subKey}` : subKey] = subValue
      }
    }
  }
  return result
}

/**
 * Returns an object representing all the available docs links keys and their
 * corresponding link "recipe" (with the variable names). It is constructed by
 * using the default links, replacing them with the translated links if the
 * locale is `de`, and then replacing them with the theme links if they exist.
 */
const generateDocsLinks = (): { [T in string]: string } => {
  let result = defaultLinks as DocsValue
  if (i18n.global.locale.value.includes('de')) {
    result = mergeDocsLinks(result, germanLinks)
  }
  if (texts.docs.links) {
    result = mergeDocsLinks(result, texts.docs.links)
  }
  return flattenDocsValue(result)
}

/**
 * Returns the docs link corresponding to the key passed as parameter. The link
 * comes from (by order of priority):
 * 1. the theme
 * 2. the translated links
 * 3. the default links
 *
 * The docs links variables from the theme are also used in place of the link
 * `{variable}`s.
 * @param key the key for the desired link.
 * @returns the compiled link.
 */
export function docsLink (key: string): string|null {
  const variables = {
    ...flattenDocsValue(texts.docs.variables),
    locale: i18n.global.locale.value.substring(0, 2),
  }
  const docsLinks = generateDocsLinks()

  let link = docsLinks[key]

  if (link === undefined) {
    return null
  }

  for (const [key, value] of Object.entries(variables)) {
    link = link.replace(`{${key}}`, value)
  }

  return link
}

/**
 * Returns a fallback name based on the provided German and English names.
 * If both names are provided, it returns the name based on the current locale.
 * If only one name is provided, it returns that name.
 * If no names are provided, it returns an empty string.
 * @param nameDE The German name.
 * @param nameEN The English name.
 * @returns The fallback name.
 * @important The order of the parameters is important
 */
export function getFallbackName (nameDE: string|undefined, nameEN: string|undefined): string {
  if (!!nameDE && !!nameEN) {
    return textForLocale(nameDE, nameEN)
  }

  return nameDE ?? nameEN ?? ''
}
