<template>
  <div
    id="menu-wrapper"
    ref="wrapper"
  >
    <v-menu
      location="bottom"
      :disabled="!hasMultipleAssets"
      class="asset-selector__menu"
      :max-height="maxMenuHeight"
      attach="#menu-wrapper"
      max-width="184"
      @update:model-value="$emit('asset-selector:toggled', $event)"
    >
      <template #activator="{ props: menuProps }">
        <div
          id="assetSelectorActivator"
          :class="['asset-selector__activator tw-rounded', { 'asset-selector__activator--enabled': hasMultipleAssets }]"
          data-cy="navigation-asset-selector-activator"
          v-bind="menuProps"
        >
          <NavigationHeader
            :text="selectedItemText"
          />
          <v-icon
            v-if="hasMultipleAssets"
            class="asset-selector__activator-icon"
            color="neutral"
            size="14"
          >
            fa:far fa-caret-down
          </v-icon>
        </div>
      </template>
      <v-sheet
        class="asset-selector bg-neutral-darken4"
        data-cy="navigation-asset-selector-items"
      >
        <v-text-field
          v-if="showSearch"
          v-model="filter"
          autofocus
          data-testid="asset-selector-search"
          bg-color="transparent"
          class="asset-selector__search asset-item-bg"
          density="compact"
          flat
          hide-details
          :placeholder="t('search')"
          single-line
          variant="solo"
          @click.stop
        />
        <NavigationItem
          v-for="(item, index) in filteredItems"
          :key="`project_item_${index}`"
          :active="isSelected(item)"
          :data-cy="`asset-selector-item`"
          class="asset-item-bg"
          :title="item.title"
          @click="onItemSelected(item)"
        />
      </v-sheet>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AssetItemData } from './types'
import NavigationHeader from './NavigationHeader.vue'
import NavigationItem from './NavigationItem.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'NavigationAssetSelector',

  components: {
    NavigationHeader,
    NavigationItem,
  },

  props: {
    items: {
      required: true,
      type: Array as PropType<Array<AssetItemData>>,
    },

    value: {
      required: false,
      type: Number as PropType<number>,
    },
  },

  emits: ['asset-selector:change', 'asset-selector:toggled'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      distanceFromTop: 0 as number,
      filter: '' as string,
      timeoutID: null as number|null,
      windowHeight: window.innerHeight as number,
    }
  },

  computed: {
    filteredItems (): Array<AssetItemData> {
      if (this.filter.length > 0) {
        const lowerCaseFilter = this.filter.toLowerCase()
        const result: Array<AssetItemData> = this.items.filter(
          (item: AssetItemData) => {
            return item.title.toLowerCase().includes(lowerCaseFilter)
          },
        )
        return result
      } else {
        return this.items
      }
    },

    hasMultipleAssets (): boolean {
      return this.items.length > 1
    },

    maxMenuHeight (): number {
      return this.windowHeight - this.distanceFromTop - 24
    },

    selectedItemText (): string {
      if (this.value && this.value !== null) {
        const item = this.items.find(item => item.value === this.value)
        return item?.title ?? ''
      } else {
        return ''
      }
    },

    showSearch (): boolean {
      const itemHeight = 40
      const maxItems = Math.floor((this.maxMenuHeight - 236) / itemHeight)
      return this.items?.length > maxItems
    },
  },

  mounted () {
    window.addEventListener('resize', this.onResize)

    this.distanceFromTop = (this.$refs.wrapper as HTMLElement)?.getBoundingClientRect().top
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
    clearTimeout(this.timeoutID as number)
  },

  methods: {
    isSelected (item: AssetItemData): boolean {
      return item.value === this.value
    },

    onItemSelected (item: AssetItemData): void {
      this.$emit('asset-selector:change', item)
      this.filter = ''
    },

    onResize () {
      this.windowHeight = window.innerHeight
    },
  },
})
</script>

<style lang="sass" scoped>
.asset-selector
  position: relative
  border: none !important

.asset-item-bg
  background-color: rgba(var(--v-theme-neutral-lighten3), 0.2)

:deep(.v-overlay__content)
  left: 8px !important

.asset-selector__activator
  position: relative
  align-items: center
  display: flex
  padding-right: 10px
  background-color: rgba(var(--v-theme-neutral-lighten3), 0)
  .asset-selector__activator__background
    opacity: 0
  &--enabled
    cursor: pointer
    &:hover
      background-color: rgba(var(--v-theme-neutral-lighten3), 0.1)

.asset-selector__activator-icon
  margin-left: auto
  height: 20px
  width: 20px

.asset-selector__search
  & :deep(.v-input__control)
    background-color: transparent
  & :deep(.v-input__slot)
    padding-left: 10px !important
    padding-right: 10px !important
  & :deep(input)
    color: rgb(var(--v-theme-neutral-lighten3)) !important
  & :deep(input::placeholder)
    color: rgb(var(--v-theme-neutral)) !important

/* Overrides for item styles */
:deep(.item.item--active .item__title)
  color: rgb(var(--v-theme-primary-lighten2)) !important
:deep(.item.item--active .item__background)
  opacity: 0
:deep(.item.item--active:hover .item__background)
  opacity: .1

:deep(.item)
  padding-left: 12px
  padding-right: 12px
</style>

<i18n locale="de">
  {
    "search": "Suche"
  }
</i18n>

<i18n locale="en">
  {
    "search": "Search"
  }
</i18n>
