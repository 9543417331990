import { cookieconsent } from '@/cookieconsent'
import { EnabledCookies } from '../types'

const storeCookieSelection = (enabledCookies: EnabledCookies): void => {
  // @ts-ignore
  if (enabledCookies.analytics) {
    cookieconsent.accept('analytics_ux')
  } else {
    cookieconsent.accept('all', ['analytics_ux'])
  }
  localStorage.setItem('consent_timestamp', new Date(Date.now()).toISOString())
}

export { storeCookieSelection }
